
.gradiente-custome{ 
  background-color: #dfe6e9 ;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.centerContent{ 
  display: flex; 
  justify-content: center; 
  align-items: center;
} 
.responsiveImg{ 
  width: 100%; 
  height: 100%; 
} 
.imgSizeCustome{ 
  width: 100%; 
  height: 300px;
}   
.frameContainerCustome{ 
  width: 100%; 
  height: 300px; 
}
.widthFrameCustome ,.widthFrameCustome iframe { 
  width: 100% !important; 
  height: 100% !important;
}
.width-frame-responsive-maps iframe { 
  width: 100% !important; 
  height: 100% !important;
}
.width-frame-responsive-maps{ 
  height: 400px;
}