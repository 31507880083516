.cardCustome{ 
    background-color: rebeccapurple; 
    height: 500px; 
}  

.responsiveImgHeader{ 
    width: 100%;  
    height: 100%;
    object-fit: contain;
}
.height-img-custome{ 
    max-height: 50px; 
}