 

.styles-text-slider{ 
    position: absolute;
    z-index: 1000; 
    background: linear-gradient(45deg, rgba(99, 205, 218, 0.4), rgba(255, 255, 255,0.4)); 
    backdrop-filter: blur(2px);
    border-radius: 10px; 
    /* min-width: 230px; */
}   
.imgResponsiveSlider{ 
    width: 100% !important ;
    height: 100%; 
    object-fit: cover;
}
.imgSliderStylesContainer{ 
    width: 100%; 
    height: 550px;  
    aspect-ratio: 16/9;
}  
.aspect-ratio-custome{ 
    /* aspect-ratio: 21/9;  */
    overflow: hidden;
    background-color: rebeccapurple;
} 
.fs-custome-title-slider{ 
    font-size: 2rem;
}  
.fs-custome-text-slider{ 
    font-size: 1.3rem;
    font-weight: 500;
} 

.max-width-slider{ 
    max-width: 2400px;
}

@media screen and (max-width:680px) {
.fs-custome-title-slider{ 
    font-size: 2rem;
} 
.imgSliderStylesContainer{ 
    width: 100%; 
    height: 200px;  
    aspect-ratio: 16/9;
}  
}