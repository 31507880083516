.gridCustome-1{ 
    display: grid; 
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr)); 
    gap: 20px;
}  

.gridCustome-2{ 
    display: grid; 
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
    gap: 20px;
} 
.gridCustome-3{ 
    display: grid;  
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));  
    gap: 20px;
}
.background-mensaje-custome{ 
    background-color: #f5f6fa; 
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);
}  
.py-customeSections{ 
    padding: 4rem 0 ;
} 
.backgroundSections section:nth-child(odd){ 
background-color: #eeeeee;
} 

.maxWidth-custome{ 
    max-width: 1250px;
} 
/* .background-primary-custome{ 
    background-color: #f5f6fa; 
} */

@media screen and (max-width:800px)  {
    .gridCustome-1{ 
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
        padding: 20px;
    }  
    .gridCustome-2{ 
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
        padding: 20px;
    }  
    .gridCustome-3{ 
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
        padding: 20px;
    } 

}

