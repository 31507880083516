.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(40, 44, 52);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

.p-sticky-custome2{ 
  position: sticky; 
  top: 0px; 
} 
.z-indexCustome{ 
  z-index: 998;
} 
.linkStyleCustome{ 
  color: #fff; 
  font-size: 20px; 
  text-decoration: none; 
  font-weight: 500;
  position: relative;
} 
.linkStyleCustome:hover{ 
  color: #000;
}
.linkStyleCustome::before{ 
  content: ''; 
  height: 2px;  
  width: 0%;
  background-color: #000; 
  position: absolute; 
  bottom: 0; 
  transition: width linear 0.3s;
}
.linkStyleCustome:hover::before{ 
  width: 100%;
}  
.activeLink::after{ 
  content: ''; 
  height: 2px; 
  background-color: #000; 
  width: 100%;
  position: absolute; 
  bottom: 0;
  left: 0; 
}  
.styles-custome{ 
  z-index: 998;

} 

.bg-custome-prmiary{ 
  background-color: #808e9b; 
  border: none !important;
}  

.containerButtonsFloat{ 
  /* background-color: rgba(178, 190, 195, 0.5);  */
  border-radius: 10px; 
}   
.customeformContact{ 
  position: fixed;
  top: 0; 
  z-index: 1000; 
  background-color: rgba(0, 0, 0,0.5);
  transform: scale(0); 
  transition: transform linear 0.3s; 
} 
.formActive{ 
  transform: scale(1);
} 
.w-custome-container-form{ 
  width: 40%; 
  background-color: #95a5a6; 
  padding: 30px;  
  padding-top: 10px;
  border-radius: 20px;
}
.container-float-elements{ 
  display: flex; 
  justify-content: center;
  align-items: center; 
  width: 100%; 
  height: 100%;
}  
.bg-whatsapp{ 
 color: #128c7e !important ;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 

@media screen and (max-width: 768px) {
  .hamburguesaMenuStyles{ 
    position: fixed; 
    top: 0;  
    width: 100vw; 
    height: 100vh;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start; 
    transform: scale(0);
    transition: transform ease-in-out 0.3s, 
  }  
  .menuActive{  
    transform: scale(1);
  }
  .hamburguesaMenuContainer{ 
    display: flex;
    flex-direction: column !important; 
  } 
  .w-custome-container-form{ 
    width: 90%;
  } 
  .z-indexCustome{ 
    z-index: 1000;
  }
}  

.maxWidth-custome{ 
  max-width: 1250px;
}

@media screen and (max-width: 400px) {
  .w-custome-container-form{ 
    width: 100%;
  }
}  
.maxWidth-custome{ 
  max-width: 1250px;
}